import { routes } from 'src/config';
import axiosService from './axiosService';

class TransactionService {
  SetTransactionsInfo = async (data) => axiosService.post('/api/Transactions', data);

  CreateACHQPayment = async (data) => axiosService.post('/api/ACHQPayment', JSON.stringify(data));

  GetTransactionsGroupedByPayment = async (loanId) => axiosService.get(
      `${routes.transactions}/TransactionsGroupedByPaymentType/${loanId}`,
    );

  SendPaymentReportAsync = async (sendPaymentReportDTO) => axiosService.post(
      `${routes.transactions}/SendPaymentReport`,
      sendPaymentReportDTO,
    );

  CreateACHQPaymentWithLInk = async (data) => axiosService.post('/api/ACHQPayment/PaymentLinkACHQ', JSON.stringify(data));

  SetAutoPaymentInfoWithLInk = async (data) => axiosService.post(
      '/api/ACHQPayment/SetAutoPaymentInfoWithLInk',
      JSON.stringify(data),
    );
}

const transactionService = new TransactionService();
export default transactionService;
