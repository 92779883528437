export const commonEn = {
  Account: 'Account',
  'Account Number': 'Account Number',
  accountlocked: 'Account Locked',
  actions: 'Actions',
  active: 'Active',
  all: 'All',
  'Active users': 'Active Users',
  activity: 'Activity',
  additional: 'Additional',
  additionalObservations: 'Additional Observations',
  administrativeCareer: 'Administrative Career',
  Adress: 'Adress',
  allFieldsAreNecesary: 'All fields are necessary',
  Amount: 'Amount',
  'Amount available': 'Amount Available',
  'And password': 'and password',
  'Apply Pay': 'Apply Pay',
  applyFor: 'Apply for',
  appointmentByResolution: 'Appointment by resolution',
  Approve: 'Approve',
  'Approve loan': 'Approve Loan',
  Approved: 'Approved',
  Ascending: 'Ascending',
  'Assign quota': 'Assign Auota',
  associatedChargesTtl: 'Associated Charges',
  Azul: 'Blue',
  back: 'Back',
  Back: 'Volver',
  'Back to home': 'Back to Home',
  Bank: 'Bank',
  'Banks Info': 'Banks Info',
  betweenOneAndThreeYears: 'Between one and three years',
  biAnnual: 'Biannual',
  Bienvenido: 'Welcome',
  'Birth date': 'Birth Date',
  brandTheme: 'Brand Theme',
  brokerageContract: 'Brokerage Contract',
  Bullet: 'Bullet',
  cancel: 'Cancel',
  Cancel: 'Cancel',
  capitalRentier: 'Capital Rentier',
  Captures: 'Captures',
  'Captures Customer': 'Captures Customer',
  capturesNotFound: 'Captures were not found',
  categoryA: 'Category A',
  categoryB: 'Category B',
  categoryC: 'Category C',
  categoryD: 'Category D',
  cCorporation: 'C-Corporation',
  'Change language': 'Change Language',
  'characters left': 'characters Left',
  'Charged off': 'Charged Off',
  Charges: 'Charges',
  City: 'City',
  Client: 'Client',
  'Client registered!': 'Client registered!',
  clientDetails: 'Client Details',
  clientName: 'Client Name',
  close: 'Close',
  Close: 'Close',
  Closed: 'Closed',
  codeCreatePassword: 'Code to create password',
  Commerces: 'Commerces',
  'Commerces description': 'Activate your credit sales',
  company: 'Company',
  CompanyAddress: 'Company Address',
  companyName: 'Company Name',
  companyNotFound: 'Company not found',
  CompanyPhone: 'Company Phone',
  complete: 'Complete',
  Contact: 'Contact',
  ContractType: 'Contract Type',
  'Cookies description':
    'We use Cookies to ensure that we give you the best experience on our website.',
  Country: 'Country',
  create: 'Create',
  'Create Charge': 'Create Charge',
  'Create new account': 'Create new account',
  createPassword: 'Create Password',
  createProduct: 'Creat Product',
  'Creation date': 'Creation Date',
  creditNumber: 'Credit Number',
  Current: 'Current',
  'Current Interest': 'Current Interest',
  customer: 'Borrower',
  customerRequest: 'Borrower Application',
  customers: 'Borrowers',
  daily: 'Daily',
  dataCreditAnalysis: 'Data Credit Analysis',
  Date: 'Date',
  'Date Creation': 'Creation Date',
  dateInvalid: 'Date invalid!',
  days: 'days',
  daysAfterDisbursement: 'Days After Disbursement',
  decision: 'Decision',
  delete: 'Delete',
  Delete: 'Delete',
  'Delete Client': 'Delete Client',
  Deleted: 'Deleted',
  Department: 'Department',
  Descending: 'Descending',
  description: 'Description',
  Description: 'Description',
  'Description is required': 'Description is required',
  Details: 'Details',
  Direction: 'Direction',
  Disbursement: 'Disbursement',
  disbursementMoney: 'Disbursement Money',
  disbursementsNumber: 'Disbursements Number',
  'Discount the ': 'Discount the ',
  'Discount Value': 'Discount Value',
  discountForChargeLbl: 'Discount for the Charge: ',
  divorced: 'Divorced',
  download: 'Download',
  downloadFile: 'Download File',
  driverLicense: 'Driver License',
  Edit: 'Edit',
  editCustomer: 'Edit Borrower',
  educationalContract: 'Educational Contract',
  email: 'Email Address',
  'Email Address': 'Email Address',
  'Email Customer': 'Email Borrower',
  emailIsNotValid: 'Email is not valid',
  'Email is required': 'Email is required',
  employee: 'Employee',
  employeePartner: 'Employee - Partner',
  EmploymentSituation: 'Employment Situation',
  endDate: 'End Date',
  English: 'English',
  'Enter a value for the new quota': 'Enter a value for the new quota',
  enterCode: 'Enter the Code',
  enterDataContinueManagingYourCredits:
    'Enter your data and continue managing your credits',
  enterEmailAndCode: 'Enter your email and code that arrived in your email',
  enterHere: 'Enter here',
  'Equated Installment': 'Fixed',
  errorGettingReport: 'Error getting report',
  errorLoadingSendibluTemplates: 'Sendinblue templates cannot be loaded',
  evidence: 'Evidence',
  'Exceeds the allowed amount': 'Exceeds the allowed amount',
  'exceeds the total value to date!': 'exceeds the total value to date!',
  expand: 'Expand',
  'Expedition date': 'Expedition Date',
  familiar: 'Familiar',
  familyOwned: 'Family-owned',
  farmer: 'Farmer',
  Female: 'Female',
  fieldMustBeGratherThanZero: 'Field must be grather than zero',
  financial: 'Financial',
  financialInfo: 'Financial Information',
  finished: 'Finished',
  'First Name': 'First Name',
  'First name is required': 'First name is required',
  fixedIncome: 'Fixed Income',
  fixedTerm: 'Fixed Term',
  forbidden: 'This user does not have permissions to perform this action',
  forgotYourPassword: 'Forgot your password?',
  Fortnightly: 'Fortnightly',
  forwardCode: 'Forward Code',
  freeAppointmentOrRemoval: 'Free Appointment or Removal',
  Frequency: 'Frequency',
  Gender: 'Gender',
  'General details': 'General Details',
  generalDetails: 'General Details',
  homeViewDescription:
    'Transform each loan into a new opportunity with our online Software. Create a new fully digital business model in a matter of minutes,'
    + 'managing loans and collections in a completely secure cloud. Receive more income with less investment and generate more opportunities for your partners and collaborators.',
  householdFinancialInfo: 'Household Financial Information',
  householdInformation: 'Household Information',
  housewife: 'Housewife',
  'I agree': 'I Agree',
  id: 'ID',
  'Identification Customer': 'Identification Borrower',
  identificationIsNotValid: 'Identification is not valid',
  imNotEmployed: "I'm not an employee",
  inactive: 'Inactive',
  income: 'income',
  incomeExpenses: 'Income and Expenses',
  incorrectUserOrPassword: 'Username does not exist ',
  indefiniteTerm: 'Indefinite Term',
  independent: 'Independent',
  independentProfessional: 'Independent Professional',
  inProgress: 'In progress',
  Installments: 'Installments',
  Interest: 'Interest',
  'Interest in arrears': 'Interest in arrears',
  Interests: 'Interest',
  internalServerError: 'At this time an internal error is occurring, try again',
  Introduction: 'Introduction',
  'Invalid issue date': 'Invalid issue date',
  invalidDate: 'Invalid Date',
  'It is required to be of legal age': 'It is required to be of legal age',
  job: 'Job',
  'Jobs Info': 'Jobs Info',
  'Last Name': 'Last Name',
  'Last name is required': 'Last name is required',
  LastNames: 'Last Names',
  lastYearDisbursements: 'Last year disbursements',
  'Lateral Menu': 'Lateral Menu',
  'Latest Projects': 'Latest Projects',
  lessOfOneYear: 'Less of one year',
  liability: 'Liability',
  Light: 'Light',
  livestock: 'Livestock',
  llc: 'L L C',
  Loading: 'Loading',
  'Loan values': 'Loan Values',
  Loans: 'Search Loans',
  LOANS: 'LOANS',
  'Log in': 'Log in',
  Login: 'Login',
  loginDescription:
    'Customer, application and loan management.\nSecure online credit origination and management.',
  Logout: 'Logout',
  Male: 'Male',
  manage: 'Management',
  management: 'Management',
  managementActivity: 'Management Activity',
  manageYourLoans: 'Manage your Loans',
  married: 'Married',
  maxFileSize: 'Max file size 4mb',
  'Maximum amount': 'Maximum Amount',
  'Maximum discount': 'Maximum Discount',
  maximum1000Characters: 'Maximum 1000 characters',
  merchant: 'Merchant',
  Message: 'Message',
  Minimum: 'Minimum',
  'minimum invalid value!': 'minimum invalid value!',
  Month: 'Month',
  monthly: 'Monthly',
  Monthly: 'Monthly',
  Months: 'Months',
  moreOfThreeYears: 'More of three years',
  'Must be a valid email': 'Must be a valid email',
  MustBeOnlyIntegers: 'Must be only integers',
  mustBeOnlyLetters: 'Must be only letters',
  MustBeOnlyPositive: 'Must be only positive',
  'Name and Lastname': 'Name and Lastname',
  netAmount: 'Net Amount',
  'New maximum quota': 'New maximum quota',
  'New projects': 'New Projects',
  newPassword: 'New Password',
  next: 'Next',
  Next: 'Next',
  'Next page': 'Next Page',
  no: 'No',
  'No Address': 'No Address',
  'No bank information': 'No Bank Information',
  'No image found on server': 'No image found on server',
  'No Jobs information': 'No Jobs Information',
  'No Phone Infomation': 'No Phone Infomation',
  'No records found': 'No records found',
  'no-records-file-name': 'no-records-en.svg',
  noActivities: 'No Activities Register',
  noData: 'No data',
  'Non Equated Installment': 'Non Equated Installment',
  none: 'None',
  noPaymentsFound:
    'Oops! At least one payment was found after the date of this installment. Reverse the payment (s) first. ',
  noRecords: 'There are no records to show as of now',
  noReversedPayments:
    'Oops! You cannot reverse installments with assigned payments. Reverse the payment (s) first and then try the fee.',
  notAlloedReverseTransactions:
    'Sorry, You are not allowed to reverse transactions in between other transactions',
  notApplicable: 'Not Applicable',
  notifications: 'Notifications',
  'Number is required!': 'Number is required!',
  Occupation: 'Occupation',
  Ok: 'Accept',
  once: 'Once',
  Organization: 'Organization',
  other: 'Other',
  Overdue: 'Overdue',
  overdueAmount: 'Overdue Amount',
  owned: 'Owned',
  Owner: 'Owner',
  'Page views per second': 'Page views per second',
  'Paid off': 'Paid Off',
  Partial: 'Partial',
  partner: 'Partner',
  partnership: 'Partnership',
  password: 'Password',
  Password: 'Password',
  'Password is required': 'Password is required',
  passwordConfirmation: 'Password confirmation',
  passwordsMustMatch: 'Passwords must match',
  Pay: 'Pay',
  'Pay date': 'Pay Date',
  'Pay Date': 'Pay Date',
  Payment: 'Payment',
  registerPayment: 'REGISTER PAYMENT',
  'payment method': 'Payment Method',
  'Payment Start': 'Payment Start',
  Payroll: 'Payroll',
  'Payroll description': 'Activate Credit Lines',
  Pending: 'Pending',
  'Pending Amount': 'Pending Amount',
  'Pending Net Amount': 'Pending Net Amount',
  'Pending Tax Amount': 'Pending Tax Amount',
  pendingTotalAmount: 'Valor Pendiente Total',
  pensionary: 'Pensionary',
  'Performance Over Time': 'Performance Over Time',
  person: 'Person',
  personal: 'Personal',
  'Personal info': 'Personal Info',
  'Phone Customer': 'Phone Borrower',
  'Phone invalid!': 'Phone invalid!',
  'Phone is required': 'Phone is required',
  'Phone Number': 'Phone Number',
  phones: 'Phones',
  Platform: 'Platform',
  presumptiveTermContract: 'Presumptive term contract',
  Previous: 'Previous',
  primary: 'Primary',
  Principal: 'Principal',
  'Principal and interests': 'Principal and interests',
  'Product Type': 'Product Type',
  Profile: 'Profile',
  'Profile updated': 'Profile updated',
  'Project Name': 'Project Name',
  propertyInformation: 'Property Information',
  propietorship: 'Propietorship',
  proprietaryAppointment: 'Proprietary Appointment',
  provisionalAppointment: 'Provisional Appointment',
  provisionOfServices: 'Provision of Services',
  quarterly: 'Quarterly',
  recoveryPassword: 'Password Recovery',
  recoveryPasswordDescription:
    'Secure online credit origination and management',
  'References Info': 'References Info',
  Refresh: 'Refresh',
  registerNaturalPerson: 'Register Natural Person',
  'Reject loan': 'Reject loan',
  Rejected: 'Rejected',
  removeCustomer: 'Remove Borrower',
  rented: 'Rented',
  repeatPassword: 'Repeat Password',
  reports: 'Reports',
  request: 'Application',
  'Request sent': 'Application Sent',
  Requests: 'Applications',
  Required: 'Required',
  resourceForbidden:
    'This user does not have permissions to perform this action',
  result: 'Result',
  returned: 'Returned',
  Reversal: 'Reversal',
  reverseDisbursement:
    'Sorry, you cannot reverse a Disbursement with Payments assigned',
  'Rows per page': 'Rows per page',
  rowsPerPage: 'Rows per page',
  rpCreated: 'Guarantor created successfully',
  'Saas description': 'Applied to your business model',
  Salary: 'Salary',
  save: 'Save',
  'Save Changes': 'Save Changes',
  saveSettings: 'Save Settings',
  Saving: 'Saving',
  secondary: 'Secondary',
  'See all': 'See All',
  'Selecciona para empezar': 'Select to Start',
  select: 'Select ---',
  selectCountry: 'Select Country',
  selectOriginator: 'Select Originator',
  selfEmployed: 'Self-employed',
  'Semi-monthly': 'Semi-monthly',
  sessionExpired: 'Your session has expired',
  Settings: 'Settings',
  'Settings updated': 'Settings updated',
  'Settings updated description':
    'We automatically updated your settings. You change the settings any time from your dashboard settings.',
  'Sign in': 'Sign in',
  'Sign in description': 'Sign in on the internal platform',
  signatureRequested: 'Signature requested',
  single: 'Single',
  solePropietorship: 'Sole Propietorship',
  Sort: 'Sort',
  Spanish: 'Spanish',
  Staff: 'Staff',
  startDate: 'Start Date',
  Status: 'Status',
  student: 'Student',
  successfulDownload: 'Successful download',
  Switch: 'Switch',
  'System Health': 'System Health',
  systemAvailability: 'System availability during the month',
  Tax: 'Tax',
  'Tax Amount': 'Tax Amount',
  'Team Tasks': 'Team Tasks',
  Technology: 'Technology',
  temporaryAppointment: 'Temporary Appointment',
  "That doesn't seem like a number": 'It is not a number',
  TimeInJob: 'Time in Job',
  Today: 'Today',
  'Too Long!': 'Too Long!',
  'Too Short!': 'Too Short!',
  toResetPassword: 'Reset your password',
  'Total Amount': 'Total Amount',
  Transactions: 'Transactions',
  TransUnionAnalysis: 'Análisis TransUnion',
  Type: 'Type',
  'Type charge': 'Type Charge',
  'Type Identification is required': 'Type Identification is required',
  typeOfHousing: 'Type Of Housing',
  unauthorized: 'This user does not have permissions to perform this action',
  underStudy: 'Underwriting',
  unemployed: 'Unemployed',
  unemployedWithIncome: 'Unemployed with income',
  unemployedWithoutIncome: 'Unemployed without income',
  unidentifiedCustomer: 'Unidentified borrower',
  unmarried: 'Unmarried',
  Update: 'Update',
  'Update changes': 'Update changes',
  uploadFile: 'Upload File',
  Use: 'Use',
  useAStrongPassword:
    'Use a strong password with uppercase, lowercase, and numbers.',
  userDisabled: 'User disabled',
  validations: 'Confirmations',
  validEmailErrMsg: 'must be a valid email',
  Value: 'Value',
  verify: 'Verify',
  verifyYourEmail: 'Verify your email',
  View: 'View',
  waiting: 'Wait a moment...',
  'Was successfully saved': 'Was successfully saved',
  'We will use this email to contact': 'We will use this email to contact',
  'We will use this email to contact you':
    'We will use this email to contact you',
  'We will use this phone to contact': 'We will use this phone to contact',
  weekly: 'Weekly',
  Weekly: 'Weekly',
  Welcome: 'Welcome',
  whatsOnYourMind: "What's on your mind",
  'why is the client deleted?': 'why is the client deleted?',
  widowed: 'Widowed',
  withoutOriginator: 'Without originator',
  workLabor: 'Work labor',
  'Write a short note about why the record should be approved':
    'Write a short note about why the record should be approved',
  'Write a short note about why the record should be edited':
    'Write a short note about why the record should be edited',
  'Write a short note about why the record should be rejected':
    'Write a short note about why the record should be rejected',
  'Write a short note about why the record should be removed':
    'Write a short note about why the record should be removed',
  'Write a short note with your request about why the customer is removed':
    'Write a short note with your application about why the customer is removed',
  wrongSendinblueSettings: 'Sendinblue Settings no valid',
  yearly: 'Yearly',
  yes: 'Yes',
  yourLoginFailed: 'Username and password do not match',
  Active: 'Active',
  months: 'Months',
  month: 'Month',
  dateMustBeLaterThan: 'Date must be greater than',
  sendinblueId: 'SendinBlue Template',
  campaigns: 'Campaigns',
  rules: 'Rules',
  addToLastActiveInstallment: 'Add to last active rate',
  feeAddedSuccessfully: 'Fee added successfully',
  errorAddingFee: 'Error adding fee',
  loanNotExist: 'This loan does not exist',
  doesNotExistChargeOrLoanInstallment: 'Does not exist charge or installment',
  loanInstallmentWasPayedOrDeleted: 'Loan installment was payed or deleted',
  chargeInstallmentWasMovedSuccessfully:
    'Charge installment was moved Successfully',
  chargeTypeDoesNotExist: 'Charge type does not exist',
  currentInstallment: 'current installment',
  noAssociatedInstallment: 'No associated',
  chargeToNextInstallment: 'Charge to the next installment',
  moveChargeToNextInstallment: 'Move the charge to the next installment?',
  collectItNextInstallment: 'Collect it in the next installment',
  thereAreNoChargesForDiscount: 'There are no charges for discount',
  selectedChargeNotAllowedToBeDiscounted:
    'The selected charge is not allowed to be discounted',
  discountOutweighsCharge: 'Discount outweighs charge',
  discountCannotBeAppliedIsAt: 'The discount cannot be applied is at 0',
  discountCreatedSuccessfully: 'Discount created successfully',
  thereIsNotActiveInstallment: 'There is not an active installment',
  addChargeToCurrentInstallment: 'Add the charge to the current installment?',
  chargeToCurrentInstallment: 'Charge to the current installment',
  doesNotExistChargeOrAlreadyHasAnInstallment:
    'Does not exist charge or already has an installment associated',
  chargeInstallmentWasAddedSuccessfully:
    'Charge installment was added successfully',
  principalBalance: 'Ending Balance',
  fileTypeNoExist: 'File type no exist',
  payNow: 'Pay Now',
  generateNew: 'New',
  dateMustBeLessThan: 'The date must be less than',
  Reversed: 'Reversed',
};

export const commonEs = {
  Account: 'Cuenta',
  'Account Number': 'Número de cuenta',
  accountlocked: 'Cuenta bloqueada',
  actions: 'Acciones',
  Actions: 'Acciones',
  active: 'Activo',
  all: 'Todos',
  'Active users': 'Usuarios activos',
  activity: 'Actividad',
  additional: 'Adicional',
  additionalObservations: 'Observaciones Adicionales',
  administrativeCareer: 'Carrera administrativa',
  Adress: 'Adress',
  Amount: 'Valor',
  'Amount available': 'Monto disponible',
  'And password': 'y la contraseña',
  'Apply Pay': 'Aplicar Pagar',
  applyFor: 'Aplica para',
  appointmentByResolution: 'Nombramiento por resolución',
  Approve: 'Aprobar',
  'Approve loan': 'Aprobar préstamo',
  Approved: 'Aprobado',
  Ascending: 'Ascendente',
  'Assign quota': 'Asignar Cuota',
  associatedChargesTtl: 'Cargos asociados',
  Azul: 'Azul',
  back: 'Volver',
  Back: 'Volver',
  'Back to home': 'Volver al inicio',
  Bank: 'Banco',
  'Banks Info': 'Información bancaria',
  betweenOneAndThreeYears: 'Entre 1 y 3 años',
  biAnnual: 'Semestral',
  Bienvenido: 'Bienvenido',
  'Birth date': 'Fecha nacimiento',
  brandTheme: 'Tema',
  brokerageContract: 'Contrato de corretaje',
  Bullet: 'Al vencimiento',
  cancel: 'Cancelar',
  Cancel: 'Cancelar',
  capitalRentier: 'Rentista de capital',
  Captures: 'Capturas',
  'Captures Customer': 'Capturas Cliente',
  capturesNotFound: 'Las capturas no fuerón encontradas',
  categoryA: 'Categoria A',
  categoryB: 'Categoria B',
  categoryC: 'Categoria C',
  categoryD: 'Categoria D',
  cCorporation: 'C-Empresa',
  'Change language': 'Cambiar idioma',
  'characters left': 'caracteres disponibles',
  'Charged off': 'Castigado',
  Charges: 'Cargos',
  City: 'Ciudad',
  Client: 'Cliente',
  'Client registered!': '¡Cliente registrado!',
  clientDetails: 'Detalle de cliente',
  clientName: 'Nombre del cliente',
  close: 'Cerrar',
  Close: 'Cerrar',
  Closed: 'Cerrado',
  codeCreatePassword: 'Código para crear contraseña',
  Commerces: 'Comercios',
  'Commerces description': 'Activa tus ventas a crédito',
  company: 'Empresa',
  CompanyAddress: 'Dirección de la empresa',
  companyName: 'Nombre de la empresa',
  companyNotFound: 'La compañia no existe',
  CompanyPhone: 'Teléfono de la empresa',
  complete: 'Completar',
  Contact: 'Contacto',
  ContractType: 'Tipo de contrato',
  'Cookies description':
    'Este sitio web utiliza cookies para garantizar que obtenga la mejor experiencia en nuestro sitio.',
  Country: 'País',
  create: 'Crear',
  'Create Charge': 'Crear Cargo',
  'Create new account': 'Crear cuenta nueva',
  createPassword: 'Crear contraseña',
  createProduct: 'Crear Producto',
  'Creation date': 'Fecha de creación',
  creditNumber: 'Número de crédito',
  Current: 'Corriente',
  'Current Interest': 'Interés Corriente',
  customer: 'Cliente',
  customerRequest: 'Solicitudes del cliente',
  customers: 'Clientes',
  daily: 'Diaria',
  dataCreditAnalysis: 'Análisis DataCrédito',
  Date: 'Fecha',
  'Date Creation': 'Fecha de creación',
  dateInvalid: 'Fecha inválida!',
  days: 'días',
  daysAfterDisbursement: 'Dias despues del desembolso',
  decision: 'Decisión',
  Delete: 'Borrar',
  'Delete Client': 'Eliminar Cliente',
  Deleted: 'Borrado',
  Descending: 'Descendente',
  description: 'Descripción',
  Description: 'Descripción',
  'Description is required': 'Descripción es requerida',
  Details: 'Detalles',
  Direction: 'Dirección',
  Disbursement: 'Desembolso',
  disbursementMoney: 'Dinero desembolsado',
  disbursementsNumber: 'Número de desembolsos',
  'Discount the ': 'Descuento de ',
  'Discount Value': 'Valor descuento',
  discountForChargeLbl: 'Descuento para el cargo de: ',
  divorced: 'Divorciado/a',
  Documentation: 'Documentación',
  download: 'Descargar',
  downloadFile: 'Descargar archivo',
  driverLicense: 'Licencia de conducción',
  Edit: 'Editar',
  editCustomer: 'Editar cliente',
  educationalContract: 'Contrato educativo',
  email: 'Correo electrónico',
  'Email Address': 'Dirección de correo electrónico',
  'Email Customer': 'Correo electrónico Cliente',
  emailIsNotValid: 'Correo electrónico no es válido',
  'Email is required': 'El correo electrónico es requerido',
  employee: 'Empleado',
  employeePartner: 'Empleado - socio',
  EmploymentSituation: 'Situación laboral',
  endDate: 'Fecha de fin',
  English: 'Inglés',
  'Enter a value for the new quota': 'Digite un valor para el nuevo cupo',
  enterCode: 'Ingresa el código',
  enterDataContinueManagingYourCredits:
    'Ingresa tus datos y continúa administrando tus créditos',
  enterEmailAndCode:
    'Ingresa tu correo electrónico y código que llegó a tu correo',
  enterHere: 'Ingresa aquí',
  'Equated Installment': 'Cuota fija',
  errorGettingReport: 'Error al consultar el reporte',
  errorLoadingSendibluTemplates:
    'No se pudieron cargar las plantillas de Sendinblue',
  evidence: 'Evidencia',
  'Exceeds the allowed amount': 'Supera el monto permitido',
  'exceeds the total value to date': 'excede el valor total a la fecha',
  expand: 'Expandir',
  'Expedition date': 'Fecha de expedición',
  familiar: 'Familiar',
  familyOwned: 'Familiar',
  farmer: 'Agricultor',
  Female: 'Femenino',
  fieldMustBeGratherThanZero: 'El campo debe ser mayor a cero',
  financial: 'Financiera',
  financialInfo: 'Información Financiera',
  finished: 'Finalizado',
  'First Name': 'Primer Nombre',
  'First name is required': 'Se requiere el primer nombre',
  fixedIncome: 'Ingresos fijos',
  fixedTerm: 'Término fijo',
  forbidden: 'Este usuario no tiene permisos para realizar esta acción',
  forgotYourPassword: '¿Olvidó su contraseña?',
  Fortnightly: 'Catorcenal',
  forwardCode: 'Reenviar código',
  freeAppointmentOrRemoval: 'Libre nombramiento o remoción',
  Frequency: 'Frecuencia',
  Gender: 'Género',
  'General details': 'Detalles generales',
  generalDetails: 'Detalles generales',
  homeViewDescription:
    'Transforma cada préstamo en una nueva oportunidad con nuestro Software en línea. Crea un nuevo modelo de negocio totalmente digital en cuestión de '
    + 'minutos, administrando préstamos y cobros en una nube completamente segura. Recibe más ingresos con menos inversión y genera más oportunidades para tus socios'
    + ' y colaboradores.',
  householdFinancialInfo: 'Información Financiera del Hogar',
  householdInformation: 'Información del Hogar',
  housewife: 'Ama de casa',
  'I agree': 'De acuerdo',
  id: 'ID',
  'Identification Customer': 'Identificación Cliente',
  identificationIsNotValid: 'La identificación no es válida',
  imNotEmployed: 'No soy empleado',
  inactive: 'Inactivo',
  income: 'Ingreso',
  incomeExpenses: 'Ingresos y Egresos',
  incorrectUserOrPassword: 'El usuario no existe',
  indefiniteTerm: 'Término indefinido',
  independent: 'Indepedendiente',
  independentProfessional: 'Profesional independiente',
  inProgress: 'En progreso',
  Installments: 'Cuotas',
  Interest: 'Interés',
  'Interest in arrears': 'Interés Mora',
  Interests: 'Intereses',
  internalServerError:
    'En este momento se esta presentando un error interno, intentalo de nuevo',
  Introduction: 'La introducción',
  'Invalid issue date': 'Fecha de Expedición Inválida',
  invalidDate: 'Fecha invalida',
  'It is required to be of legal age': 'Se requiere ser mayor de edad',
  job: 'Laboral',
  'Jobs Info': 'Información laboral',
  'Last Name': 'Apellido',
  'Last name is required': 'Se requiere apellido',
  LastNames: 'Apellidos',
  lastYearDisbursements: 'Desembolsos último año',
  'Lateral Menu': 'Menú lateral',
  'Latest Projects': 'Últimos proyectos',
  lessOfOneYear: 'Menos de 1 año',
  liability: 'Pasivo',
  Light: 'Claro',
  livestock: 'Ganadero',
  llc: 'L L C',
  Loading: 'Cargando',
  'Loan values': 'Valores de préstamos',
  Loans: 'Buscar préstamos',
  LOANS: 'PRESTAMOS',
  'Log in': 'Ingresar',
  Login: 'Iniciar sesión',
  loginDescription:
    'Administración de clientes, solicitudes y préstamos.\nOriginación y gestión segura de crédito en línea.',
  Logout: 'Salir',
  Male: 'Masculino',
  manage: 'Gestionar',
  management: 'Administracion',
  managementActivity: 'Actividad de administracion',
  manageYourLoans: 'Administre sus préstamos',
  married: 'Casado/a',
  maxFileSize: 'Tamaño máximo de archivos 4mb',
  'Maximum amount': 'Monto máximo',
  'Maximum discount': 'Descuento Máximo',
  maximum1000Characters: 'Máximo 1000 caracteres',
  merchant: 'Comerciante',
  Message: 'Mensaje',
  Minimum: 'Mínimo',
  'minimum invalid value!': '¡Valor mínimo inválido!',
  Month: 'Mes',
  monthly: 'Mensual',
  Monthly: 'Mensual',
  Months: 'Meses',
  moreOfThreeYears: 'Más de 3 años',
  'Must be a valid email': 'Debe ingresar un correo electrónico valido',
  MustBeOnlyIntegers: 'Deben ser solo números enteros',
  mustBeOnlyLetters: 'Deben ser solo letras',
  MustBeOnlyPositive: 'Deben ser solo números positivos',
  'Name and Lastname': 'Nombre y apellido',
  netAmount: 'Valor Neto',
  'New maximum quota': 'Nuevo Monto máximo',
  'New projects': 'Nuevos proyectos',
  newPassword: 'Nueva contraseña',
  next: 'Siguiente',
  Next: 'Siguiente',
  'Next page': 'Página siguiente',
  no: 'no',
  'No Active': 'No Activo',
  'No Address': 'Sin dirección',
  'No bank information': 'Sin información bancaria',
  'No image found on server': 'No se encontró imagen',
  'No Jobs information': 'Sin información de trabajo',
  'No Phone Infomation': 'Sin información de teléfono',
  'No records found': 'No se encontraron registros',
  'no-records-file-name': 'no-records-es.svg',
  noActivities: 'No se registran actividades',
  noData: 'Sin datos',
  'Non Equated Installment': 'Cuota variable',
  none: 'Ninguno',
  noPaymentsFound:
    'Ups! Se encontró al menos un pago después de la fecha de esta cuota. Reversa primero el(los) pago(s).',
  noRecords: 'No hay registros para mostrar por ahora',
  noReversedPayments:
    'Ups! No se pueden reversar cuotas con pagos asignados. Reversa primero el(los) pago(s) y luego intenta con la cuota.',
  notAlloedReverseTransactions:
    'Lo sentimos, no se permite revertir transacciones entre otras transacciones ',
  notApplicable: 'No aplica',
  notifications: 'Notificaciones',
  'Number is required!': 'Número es requerido!',
  Occupation: 'Ocupación',
  Ok: 'Aceptar',
  once: 'Una vez',
  Organization: 'Organización',
  other: 'Otro',
  Overdue: 'En mora',
  overdueAmount: 'Saldo en mora',
  owned: 'Propia',
  Owner: 'Líder',
  'Page views per second': 'Vista de página por segundo',
  'Paid off': 'Cancelado',
  Partial: 'Parcial',
  partner: 'Socio',
  partnership: 'Asociación',
  password: 'Contraseña',
  Password: 'Contraseña',
  'Password is required': 'La contraseña es requerida',
  passwordConfirmation: 'Confirmar contraseña',
  passwordsMustMatch: 'Las contraseñas deben coincidir.',
  Pay: 'Pagar',
  'Pay date': 'Fecha de pago',
  Payment: 'Pago',
  registerPayment: 'REGISTRAR PAGO',
  'payment method': 'Método de pago',
  'Payment Start': 'Inicio de pago',
  Payroll: 'Nómina',
  'Payroll description': 'Activa líneas de crédito',
  Pending: 'Pendiente',
  'Pending Amount': 'Valor Pendiente',
  'Pending Net Amount': 'Valor Pendiente Neto',
  'Pending Tax Amount': 'Valor Pendiente de Impuesto',
  pendingTotalAmount: 'Valor Pendiente Total',
  pensionary: 'Pensionado',
  'Performance Over Time': 'Desempeño en el tiempo',
  person: 'Persona',
  personal: 'Personal',
  'Personal info': 'Información personal',
  'Phone Customer': 'Teléfono Móvil Cliente',
  'Phone invalid!': '¡Teléfono inválido!',
  'Phone is required': 'Se requiere teléfono',
  'Phone Number': 'Número de teléfono',
  phones: 'Teléfonos',
  Platform: 'Plataforma',
  presumptiveTermContract: 'Contrato plazo presuntivo',
  Previous: 'Anterior',
  primary: 'Primario',
  Principal: 'Capital',
  'Principal and interests': 'Capital e intereses',
  'Product Type': 'Tipo de producto',
  Profile: 'Perfil',
  'Profile updated': 'Perfil actualizado',
  'Project Name': 'Nombre del proyecto',
  propertyInformation: 'Información de Bienes',
  propietorship: 'Propietario',
  proprietaryAppointment: 'Nombramiento en propiedad',
  provisionalAppointment: 'Nombramiento provisional',
  provisionOfServices: 'Prestación de servicios',
  quarterly: 'Trimestral',
  recoveryPassword: 'Recuperación de contraseña',
  recoveryPasswordDescription:
    'Originación y gestión segura de créditos en línea',
  'References Info': 'Información de referencias',
  Refresh: 'Actualizar',
  registerNaturalPerson: 'Registrar persona natural',
  'Reject loan': 'Rechazar préstamo',
  Rejected: 'Rechazado',
  removeCustomer: 'Eliminar Cliente',
  rented: 'Arrendada',
  repeatPassword: 'Repetir contraseña',
  reports: 'Reportes',
  request: 'Solicitud',
  'Request sent': 'Solicitud enviada',
  Requests: 'Solicitudes',
  Required: 'Obligatorio',
  resourceForbidden: 'Este usuario no tiene permisos para realizar esta acción',
  result: 'Resultado',
  returned: 'Devuelto',
  Reversal: 'Reversión',
  reverseDisbursement:
    'Lo sentimos, no puede revertir un desembolso con pagos asignados',
  'Rows per page': 'Filas por página',
  rowsPerPage: 'Filas por página',
  rpCreated: 'Parte relacionada creada con éxito',
  'Saas description': 'Aplicado a tu modelo de negocio',
  Salary: 'Salario',
  Save: 'Guardar',
  'Save Changes': 'Guardar cambios',
  saveSettings: 'Guardar configuración',
  Saving: 'Ahorros',
  secondary: 'Secundario',
  'See all': 'Ver todo',
  'Selecciona para empezar': 'Selecciona para empezar',
  select: 'Seleccionar ---',
  selectCountry: 'Seleccionar país',
  selectOriginator: 'Seleccionar Originador',
  selfEmployed: 'Independiente',
  'Semi-monthly': 'Quincenal',
  sessionExpired: 'Tu sesión ha expirado',
  Settings: 'Propiedades',
  'Settings updated': 'Actualización de ajustes',
  'Settings updated description':
    'Tus configuraciones son actualizadas automáticamente. Cambia la configuración en cualquier momento desde los ajustes del tablero.',
  'Sign in': 'Iniciar sesión',
  'Sign in description': 'Inciar sesión en la plataforma',
  signatureRequested: 'Firma solicitada',
  single: 'Soltero/a',
  solePropietorship: 'Empresa unipersonal',
  Sort: 'Ordenar',
  Spanish: 'Español',
  Staff: 'Personal',
  startDate: 'Fecha de inicio',
  Status: 'Status',
  student: 'Estudiante',
  successfulDownload: 'Descarga exitosa',
  Switch: 'Cambiar',
  'System Health': 'Estado del sistema',
  systemAvailability: 'Disponibilidad del sistema en el mes',
  Tax: 'Impuesto',
  'Tax Amount': 'Valor de Impuestos',
  'Team Tasks': 'Tareas del equipo',
  Technology: 'Tecnología',
  temporaryAppointment: 'Nombramiento transitorio',
  "That doesn't seem like a number": 'Eso no parece un número',
  TimeInJob: 'Tiempo Trabajando',
  Today: 'Hoy',
  'Too Long!': '¡Muy largo!',
  'Too Short!': '¡Muy corto!',
  toResetPassword: 'Para restablecer la contraseña',
  'Total Amount': 'Saldo Total',
  Transactions: 'Transacciones',
  transUnionAnalysis: 'Análisis TransUnion',
  Type: 'Tipo',
  'Type charge': 'Tipos de cargos',
  'Type Identification is required': 'Tipo de Identificación es requerida',
  typeOfHousing: 'Tipo de vivienda',
  unauthorized: 'Este usuario no tiene permisos para realizar esta acción',
  underStudy: 'En estudio',
  unemployed: 'Desempleado',
  unemployedWithIncome: 'Desempleado con ingresos',
  unemployedWithoutIncome: 'Desempleado sin ingresos',
  unidentifiedCustomer: 'Cliente no identificado',
  unmarried: 'Unión libre',
  Update: 'Actualizar',
  'Update changes': 'Actualizar cambios',
  uploadFile: 'Subir archivo',
  Use: 'Utilice',
  useAStrongPassword:
    'Usa una contraseña segura con mayúsculas, minúsculas y números.',
  userDisabled: 'Usuario desactivado',
  validations: 'Validaciones',
  validEmailErrMsg: 'debe ser un correo electrónico válido',
  Value: 'Valor',
  verify: 'Verificar',
  verifyYourEmail: 'Verifica tu correo electrónico',
  View: 'Ver',
  waiting: 'Espera un momento...',
  'Was successfully saved': 'Se guardó exitosamente',
  'We will use this email to contact':
    'Utilizaremos este correo electrónico como contacto',
  'We will use this email to contact you':
    'Usaremos este correo electrónico para comunicarnos contigo',
  'We will use this phone to contact': 'Usaremos este teléfono para contactar',
  weekly: 'Semanal',
  Weekly: 'Semanal',
  Welcome: 'Bienvenido',
  whatsOnYourMind: 'Qué tienes en mente',
  'why is the client deleted?': '¿Por qué se elimina el cliente?',
  widowed: 'Viudo/a',
  withoutOriginator: 'Sin originador',
  workLabor: 'Obra Labor',
  'Write a short note about why the record should be approved':
    'Describa porqué el registro debe ser aprobado',
  'Write a short note about why the record should be edited':
    'Describa porqué el registro debe ser editado',
  'Write a short note about why the record should be rejected':
    'Describa porqué el registro debe ser rechazado',
  'Write a short note about why the record should be removed':
    'Describa porqué el registro debe ser borrado',
  'Write a short note with your request about why the customer is removed':
    'Escriba una nota breve con su solicitud sobre porqué se elimina el cliente',
  wrongSendinblueSettings: 'Configuracion Sendinblue no válida',
  yearly: 'Anual',
  yes: 'Si',
  yourLoginFailed: 'Usuario y contraseña no coinciden',
  Active: 'Activo',
  months: 'Meses',
  month: 'Mes',
  dateMustBeLaterThan: 'La fecha debe ser mayor a',
  sendinblueId: 'Plantilla de SendinBlue',
  campaigns: 'Campañas',
  rules: 'Reglas',
  addToLastActiveInstallment: 'Agregar a la última cuota activa',
  feeAddedSuccessfully: 'Cargo agregadó con éxito',
  errorAddingFee: 'Error al agregar el cargo',
  loanNotExist: 'Este préstamo no existe',
  doesNotExistChargeOrLoanInstallment: 'No existe cargo ni cuota',
  loanInstallmentWasPayedOrDeleted:
    'La cuota del préstamo fue pagada o eliminada',
  chargeInstallmentWasMovedSuccessfully: 'El cargo se movió con éxito',
  chargeTypeDoesNotExist: 'El tipo de cargo no existe',
  currentInstallment: 'Couta actual',
  noAssociatedInstallment: 'Sin cuota',
  chargeToNextInstallment: 'Cargo a la siguiente cuota',
  moveChargeToNextInstallment: '¿Mover el cargo a la siguiente cuota?',
  collectItNextInstallment: 'Cobrar en la próxima cuota',
  thereAreNoChargesForDiscount: 'No hay cargos para descuento',
  selectedChargeNotAllowedToBeDiscounted:
    'El cargo seleccionado no tiene permitido hacerle descuentos',
  discountOutweighsCharge: 'El descuento supera el cargo',
  discountCannotBeAppliedIsAt: 'El descuento no se puede aplicar esta en 0',
  discountCreatedSuccessfully: 'Descuento creado exitosamente',
  thereIsNotActiveInstallment: 'No hay una cuota activa',
  addChargeToCurrentInstallment: '¿Agregar el cargo a la cuota actual?',
  chargeToCurrentInstallment: 'Cargo a la cuota actual',
  doesNotExistChargeOrAlreadyHasAnInstallment:
    'No existe el cargo o ya tiene una cuota asociada',
  chargeInstallmentWasAddedSuccessfully:
    'el cargo a la cuota se agregó con éxito',
  principalBalance: 'Saldo principal',
  fileTypeNoExist: 'El tipo de archivo no existe',
  payNow: 'Pagar ahora',
  generateNew: 'Nuevo',
  dateMustBeLessThan: 'La fecha debe ser menor a',
  Reversed: 'Reversado',
};
