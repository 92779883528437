import axios from 'src/utils/axios';
import routes from 'src/config/api.routes';
import { API_BASE_URL } from 'src/config/';
import axiosService from './axiosService';

class LoanService {
  GetLoans = async (filter) => axiosService.get(`${routes.loans}${filter}`);

  GetLoan = (loanId) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Loans/${loanId}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: response.data,
            };
            resolve(db);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetDetailInfoLoan = (loanId, date) => axiosService.get(`/api/Loans/detailedInfo/${loanId}/${date}`);

  GePayOffInfoLoan = (loanId, date, prepaymentPenalty, daysLimit = 10) => axiosService.get(
      `/api/Loans/getPayOffLoanInfo/${loanId}/${date}/${prepaymentPenalty}/${daysLimit}`,
    );

  sendDocumentPayOffAsync = (sendPayOffDTO) => axiosService.post(`${routes.loans}/sendPayOffLoanInfo`, sendPayOffDTO);

  GetEntityDetails = (loanId) => axiosService.get(`/api/loans/${loanId}/entity-details`);

  GetChargeType = (loanId) => axiosService.get(`/api/Charges/loanchargeTypes/${loanId}`);

  GetChargeLoans = (loanId) => axiosService.get(`/api/Charges/${loanId}`);

  SetChargeType = (charge) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(charge);
      axios
        .post(`${API_BASE_URL}/api/Charges/`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetDiscountChargeType = (charge) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(charge);
      axios
        .post(`${API_BASE_URL}/api/LoanChargeDiscounts/`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetMoveChargeInstallment = (charge) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(charge);
      axios
        .post(`${API_BASE_URL}/api/Charges/MoveChargeToNextInstallment`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetAddChargeCurrentInstallment = (charge) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(charge);
      axios
        .post(
          `${API_BASE_URL}/api/Charges/AddChargeToCurrentInstallment`,
          data,
          {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId'),
            },
          },
        )
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetDiscountInterest = (value) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(value);
      axios
        .post(`${API_BASE_URL}/api/Discount/`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetLoanStatus = (value) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(value);
      axios
        .put(`${API_BASE_URL}/api/Loans/${value.LoanId}/Status`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId'),
          },
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message,
                processed: response.data.processed,
              },
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  DeleteLoanById = async (loanId, eventData) => axiosService.delete(`${routes.loans}/${loanId}?comment = ${eventData}`);

  GetLoansToDisburseFile = async (bankId, accountId, loanToDisbDto) => axiosService.post(
      `${routes.loans}/getLoansToDisburse?bankId=${bankId}&accountId=${accountId}`,
      loanToDisbDto,
    );

  GetLoanValidations = async (loanId) => axiosService.get(`${routes.loans}/validations/${loanId}`);

  UpdateLoanValidation = async (validation) => axiosService.put(`${routes.loans}/validations`, validation);

  DeleteLoanValidationAttachment = async (loanValidationId, path, fileName) => axiosService.delete(
      `${routes.loans}/validations/${loanValidationId}/${path}/${fileName}`,
    );

  GetLoanAutoPaymentParameters = async (loanId) => axiosService.get(`${routes.loans}/GetLoanAutopaymentParemeters/${loanId}`);

  GetLoanPaymentExtensionsActiveAsync = async (loanId) => axiosService.get(
      `${routes.loans}/GetLoanPaymentExtensionsActive/${loanId}`,
    );

  CreateLoanAutoPaymentParemeters = async (AutoPaymentParametersDTO) => axiosService.post(
      `${routes.loans}/LoanAutoPaymentParemeters`,
      AutoPaymentParametersDTO,
    );

  GetExistStripeParametersForLoan = async () => axiosService.get(`${routes.loans}/GetExistStripeParametersForLoan`);

  GetAutopaymentParameters = async () => axiosService.get(`${routes.loans}/GetAutopaymentParameters`);

  MarkLoanAvailableForInvestors = async (loanId, status) => axiosService.get(
      `${routes.loans}/MarkLoanAvailableForInvestors/${loanId}/${status}`,
    );

  GetLoanDelinquencyReports = async () => axiosService.get(`${routes.loans}/LoanDelinquencyReports`);

  GetLoanDelinquencyReportById = async (id, filter) => axiosService.get(
      `${routes.loans}/LoanDelinquencyReportById/${id}${filter}`,
    );

  GetLoanPaymentReport = async (initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/LoanPaymentReport/${initialDate}/${lastDate}/${filter}`,
    );

  GetLoansPortfolioBalanceReport = async (initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/LoansPortfolioBalanceReport/${initialDate}/${lastDate}/${filter}`,
    );

  GetLoansReceivableReport = async (initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/LoansReceivableReport/${initialDate}/${lastDate}/${filter}`,
    );

  GetLoansAccountReceivableAgingReport = async (filter) => axiosService.get(
      `${routes.loans}/LoansAccountReceivableAgingReport/${filter}`,
    );

  GetLoanReportByTypeAsync = async (loanId, reportType) => axiosService.get(
      `${routes.loans}/LoanReportByType/${loanId}/${reportType}`,
    );

  SendPaymentReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendPaymentReportToEmail`,
      sendFileToEmailsDTO,
    );

  SendEmailToSetAutoPaymentAsync = async (loanId) => axiosService.post(
      `${routes.loans}/SendEmailToSetAutoPayment?loanId=${loanId}`,
    );

  GetPaymentLink = async (loanId, newToken) => axiosService.get(`/api/Loans/GetPaymentLink/${loanId}/${newToken}`);

  GetLoanInfoByPaymentLink = async (paymentLink) => axiosService.get(`/api/Loans/LoanInfoByPaymentLink/${paymentLink}`);

  CreateLoanPaymentExtensionAsync = async (paymentExtensionDTO) => axiosService.post(
      `${routes.loans}/CreateLoanPaymentExtension`,
      paymentExtensionDTO,
    );

  GetLoanPaymentExtensionAsync = async (loanId) => axiosService.get(`${routes.loans}/GetLoanPaymentExtensions/${loanId}`);

  DownloadPaymentExtensionAttachmentById = (extensionAttachmentId, filename) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}${routes.loans}/GetPaymentExtensionFile/${extensionAttachmentId}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId'),
            },
          },
        )
        .then((response) => {
          if (response?.status === 200) {
            resolve({ response, filename });
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DeletePaymentExtensionAsync = async (loanId, paymentExtensionId) => axiosService.delete(
      `${routes.loans}/DeletePaymentExtension/${loanId}/${paymentExtensionId}`,
    );

  UpdateChargedOffInfo = async (chargeOffDto) => axiosService.put(`${routes.loans}/UpdateChargedOffReport`, chargeOffDto);

  SendLoansReceivableReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendLoansReceivableReportToEmail`,
      sendFileToEmailsDTO,
    );

  UpdateLoanParticipationInfo = async (loanParticipationDto) => axiosService.put(
      `${routes.loans}/UpdateLoanParticipationReport`,
      loanParticipationDto,
    );

  UpdateLoanStateGuaranteeInfo = async (loanStateGuaranteeDto) => axiosService.put(
      `${routes.loans}/UpdateLoanStateGuaranteeReport`,
      loanStateGuaranteeDto,
    );

  UpdateLoanGuaranteePaymentRequestedInfo =
    async (loanGuaranteePaymentRequestedDto) => axiosService.put(
        `${routes.loans}/UpdateLoanGuaranteePaymentRequestedReport`,
        loanGuaranteePaymentRequestedDto,
      );

  SendLoansPortfolioBalanceReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendLoansPortfolioBalanceReportToEmail`,
      sendFileToEmailsDTO,
    );

  SendLoansReceivableAgingReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendLoansReceivableAgingReportFileToEmail`,
      sendFileToEmailsDTO,
    );

  GetLoansReceivableSummaryReport = async (initialDate, lastDate) => axiosService.get(
      `${routes.loans}/LoansReceivableSummaryReport/${initialDate}/${lastDate}`,
    );

  GetLoansAccountReceivableAgingSummary = async (numberOfPeriods) => axiosService.get(
      `${routes.loans}/LoansAccountReceivableAgingSummaryReport/${numberOfPeriods}`,
    );

  GetStateGuaranteePortfolioReport = async () => axiosService.get(`${routes.loans}/StateGuaranteePortfolioReport`);

  GetCalCapPortfolioReport = async () => axiosService.get(`${routes.loans}/CalCapPortfolioReport`);

  SendLoansPaymentPlanFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendLoansPaymentPlanFileToEmail`,
      sendFileToEmailsDTO,
    );

  CreateOrUpdateInsuranceExpirationInformationAsync =
    async (insuranceExpirationInformationDto) => axiosService.post(
        `${routes.loans}/CreateOrUpdateInsuranceExpiration`,
        insuranceExpirationInformationDto,
      );

  GetInsuranceExpirationInfoAsync = async (loanId) => axiosService.get(`${routes.loans}/GetInsuranceExpirationInfo/${loanId}`);

  GenerateDemandLetterPdf = async (loanId) => axiosService.getBlobType(`${routes.loans}/demand-letter-pdf/${loanId}`);

  SendDemandLetterToEmailAsync = (sendDemandLetterDTO) => axiosService.post(`${routes.loans}/SendDemandLetter`, sendDemandLetterDTO);

  UpdateDefaultAndRepossession = async (defaultAndRepossessionDTO) => axiosService.post(
      `${routes.loans}/CreateOrUpdateDefaultAndRepossessionReport`,
      defaultAndRepossessionDTO,
    );

  DownloadDefaultAndRepossessionAttachmentById = (
    defaultAndRepossessionFileId,
    filename,
  ) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}${routes.loans}/GetDefaultAndRepossessionFile/${defaultAndRepossessionFileId}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId'),
            },
          },
        )
        .then((response) => {
          if (response?.status === 200) {
            resolve({ response, filename });
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DeleteDefaultAndRepossessionAttachmentAsync = async (attachmentId) => axiosService.delete(
      `${routes.loans}/DeleteDefaultAndRepossessionFile/${attachmentId}`,
    );

  SignOnDemandLetterAsync = async (loanId) => axiosService.put(`${routes.loans}/SignOnDemandLetter/${loanId}`);

  SendDemandLetterSignedToEmailAsync = (sendDemandLetterDTO) => axiosService.post(
      `${routes.loans}/SendDemandLetterSigned`,
      sendDemandLetterDTO,
    );

  GetLoansInsuranceReport = async (initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/LoansInsuranceReport/${initialDate}/${lastDate}/${filter}`,
    );

  SendInsuranceReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendInsuranceReportFileToEmail`,
      sendFileToEmailsDTO,
    );

  GetLoanBillOfSaleAsync = async (loanId) => axiosService.get(`${routes.loans}/BillOfSale/${loanId}`);

  UpdateBillOfSaleAsync = async (loanBillOfSaleDto) => axiosService.put(`${routes.loans}/UpdateBillOfSale`, loanBillOfSaleDto);

  GenerateBillOfSalePdf = async (loanId) => axiosService.getBlobType(`${routes.loans}/bill-of-sale-pdf/${loanId}`);

  GenerateInvoicePdf = async (loanId) => axiosService.getBlobType(`${routes.loans}/invoice-pdf/${loanId}`);

  SendInvoiceAndBillOfSaleDocumentsToEmailAsync =
    (sendInvoiceAndBillDocumentsToEmailsDTO) => axiosService.post(
        `${routes.loans}/SendInvoiceAndBillDocumentToEmails`,
        sendInvoiceAndBillDocumentsToEmailsDTO,
      );

  DownloadBillOfSaleFileById = (loanBillOfSaleFileId, filename) => new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}${routes.loans}/DownloadBillOfSaleFile/${loanBillOfSaleFileId}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId'),
            },
          },
        )
        .then((response) => {
          if (response?.status === 200) {
            resolve({ response, filename });
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoansCollateralReport = async (initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/LoansCollateralReport/${initialDate}/${lastDate}/${filter}`,
    );

  SendCollateralReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendCollateralReportFileToEmail`,
      sendFileToEmailsDTO,
    );

  GetLoansClosureReport = async (
    portfolioType,
    initialDate,
    lastDate,
    filter,
  ) => axiosService.get(
      `${routes.loans}/LoansClosureReport/${portfolioType}/${initialDate}/${lastDate}/${filter}`,
    );

  SendLoansClosureReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendLoansClosureReportFileToEmail`,
      sendFileToEmailsDTO,
    );

  EqLoanListReport = async (
    portfolioType,
    availableStatus,
    filteredBy,
    initialDate,
    lastDate,
    filter,
  ) => axiosService.get(
      `${routes.loans}/EqLoanListReport/${portfolioType}/${availableStatus}/${filteredBy}/${initialDate}/${lastDate}/${filter}`,
    );

  SendEqLoanListReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendEqLoanListReportFileToEmail`,
      sendFileToEmailsDTO,
    );

  GenerateLoanClosureMemoPdf = async (loanId) => axiosService.getBlobType(`${routes.loans}/loan-closure-memo-pdf/${loanId}`);

  ActiveLoansListReport = async (filteredBy, initialDate, lastDate, filter) => axiosService.get(
      `${routes.loans}/ActiveLoansListReport/${filteredBy}/${initialDate}/${lastDate}/${filter}`,
    );

  SendActiveLoansListReportFileToEmailAsync = async (sendFileToEmailsDTO) => axiosService.post(
      `${routes.loans}/SendActiveLoansListReportFileToEmail`,
      sendFileToEmailsDTO,
    );
}

const loanService = new LoanService();

export default loanService;
